import React, { ReactElement, useState } from 'react';
import {
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { getYear } from 'date-fns';

import { SelectInput } from '../components/inputs/SelectInput';
import { Page, PageProps } from '../components/layout/Page';
import { Currency } from '../components/outputs/Currency';
import { useDataStore } from '../hooks/dataStore';
import {
  TemporalAggregationType,
  toTimePeriodName,
  useTemporalAggregation,
} from '../hooks/useTemporalAggregation';
import {
  aggregationTypeOptions,
  dateTypeOptions,
  TransactionDateType,
  yearOptions,
} from '../model/Options';

export const RevenueOverviewPage = ({
  title,
}: PageProps): ReactElement | null => {
  const [temporalAggregationType, setTemporalAggregationType] =
    useState<TemporalAggregationType>('MONTH');

  const [selectedYear, setSelectedYear] = useState<string>(
    getYear(new Date()).toString(),
  );

  const [selectedDateField, setSelectedDateField] =
    useState<TransactionDateType>('dateOfPayment');

  const { transactionClassifications } = useDataStore();

  const transactionClassificationsByYear =
    temporalAggregationType === 'YEAR'
      ? transactionClassifications
      : transactionClassifications.filter(
          (tC) =>
            tC[selectedDateField] &&
            getYear(tC[selectedDateField]!).toString() === selectedYear,
        );

  const incomeTransactions = transactionClassificationsByYear.filter(
    (tC) => tC.type === 'INC',
  );
  const outstandingIncome = transactionClassifications.filter(
    (i) => i.dateOfPayment === undefined && i.type === 'INC',
  );
  const expenseTransactions = transactionClassificationsByYear.filter(
    (tC) => tC.type === 'EXP',
  );
  const incomeAggregation = useTemporalAggregation(
    incomeTransactions,
    selectedDateField,
    temporalAggregationType,
  );
  const expenseAggregation = useTemporalAggregation(
    expenseTransactions,
    selectedDateField,
    temporalAggregationType,
  );
  const outstandingIncomeAggregation = useTemporalAggregation(
    outstandingIncome,
    'dateOfInvoice',
    temporalAggregationType,
  );
  const aggregatorsList = Array.from(
    new Set(
      incomeAggregation
        .map((v) => v.aggregator)
        .concat(expenseAggregation.map((v) => v.aggregator)),
    ),
  ).sort((a, b) => a - b);

  const rows = aggregatorsList
    .map((aggregator) => {
      const incomeAggregationResult = incomeAggregation.find(
        (agg) => agg?.aggregator === aggregator,
      );
      const expenseAggregationResult = expenseAggregation.find(
        (agg) => agg?.aggregator === aggregator,
      );
      const outstandingAggregationResult = outstandingIncomeAggregation.find(
        (agg) => agg?.aggregator === aggregator,
      );
      const incomeTotal = incomeAggregationResult?.total ?? 0;
      const expensesTotal = expenseAggregationResult?.total ?? 0;
      const saldo = incomeTotal - expensesTotal;
      const outstandingTotal =
        selectedDateField === 'dateOfPayment'
          ? outstandingAggregationResult?.total ?? 0
          : undefined;
      const saldoInclOutstanding =
        incomeTotal + (outstandingTotal ?? 0) - expensesTotal;

      return {
        aggregator,
        timePeriodName: !isNaN(aggregator)
          ? toTimePeriodName(aggregator, temporalAggregationType)
          : 'ohne Periode',
        incomeTotal,
        expensesTotal,
        saldo,
        outstandingTotal,
        saldoInclOutstanding,
      };
    })
    .sort((a, b) => {
      const a1 = !isNaN(a.aggregator) ? a.aggregator : -1;
      const b1 = !isNaN(b.aggregator) ? b.aggregator : -1;
      return b1 - a1;
    });

  const incomeTotal = incomeAggregation.reduce((p, c) => p + c.total, 0);
  const expensesTotal = expenseAggregation.reduce((p, c) => p + c.total, 0);
  const outstandingTotal =
    selectedDateField === 'dateOfPayment'
      ? outstandingIncomeAggregation.reduce((p, c) => p + c.total, 0)
      : undefined;
  const saldo = incomeTotal - expensesTotal;
  const saldoInclOutstanding = saldo + (outstandingTotal ?? 0);
  const summaryRow = {
    incomeTotal,
    expensesTotal,
    outstandingTotal,
    saldo,
    saldoInclOutstanding,
    timePeriodName: 'Gesamt',
  };

  if (!transactionClassifications) {
    return null;
  }
  return (
    <Page title={title}>
      <Stack gap={2}>
        <Grid container spacing={2}>
          <Grid item lg={2} xs={3}>
            <SelectInput
              label="Zeitliche Aggregation"
              options={aggregationTypeOptions}
              value={temporalAggregationType}
              onChange={(event) =>
                setTemporalAggregationType(
                  event.target.value as TemporalAggregationType,
                )
              }
            />
          </Grid>
          <Grid item lg={1} xs={3}>
            <SelectInput
              label="Jahr"
              options={yearOptions}
              value={selectedYear}
              onChange={(event) =>
                setSelectedYear(event.target.value as string)
              }
              disabled={temporalAggregationType === 'YEAR'}
            />
          </Grid>
          <Grid item lg={2} xs={3}>
            <SelectInput
              label="Datumstyp"
              options={dateTypeOptions}
              value={selectedDateField}
              onChange={(event) =>
                setSelectedDateField(event.target.value as TransactionDateType)
              }
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <RevenueOverviewTable rows={rows} summaryRow={summaryRow} />
          </Grid>
        </Grid>
      </Stack>
    </Page>
  );
};

type RevenueOverviewRowProps = {
  timePeriodName: string;
  incomeTotal: number;
  expensesTotal: number;
  saldo: number;
  outstandingTotal: number | undefined;
  saldoInclOutstanding: number;
};

export const RevenueOverviewRow = ({
  timePeriodName,
  incomeTotal,
  expensesTotal,
  saldo,
  outstandingTotal,
  saldoInclOutstanding,
}: RevenueOverviewRowProps): ReactElement => {
  return (
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell component="th" scope="row">
        {timePeriodName}
      </TableCell>
      <TableCell align="right">
        <Currency>{expensesTotal}</Currency>
      </TableCell>
      <TableCell align="right">
        <Currency>{incomeTotal}</Currency>
      </TableCell>
      <TableCell align="right">
        <Currency>{outstandingTotal}</Currency>
      </TableCell>
      <TableCell align="right" style={{ fontWeight: 'bold' }}>
        <Currency>{saldo}</Currency>
      </TableCell>
      <TableCell align="right">
        <Currency>{saldoInclOutstanding}</Currency>
      </TableCell>
    </TableRow>
  );
};

type RevenueOverviewTableProps = {
  rows: RevenueOverviewRowProps[];
  summaryRow: RevenueOverviewRowProps;
};

const RevenueOverviewTable = ({
  rows,
  summaryRow,
}: RevenueOverviewTableProps): ReactElement => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <RevenueOverviewRow {...summaryRow} />
          <TableRow>
            <TableCell>Periode</TableCell>
            <TableCell align="right">Ausgaben</TableCell>
            <TableCell align="right">Einnahmen</TableCell>
            <TableCell align="right">Offene Forderungen</TableCell>
            <TableCell align="right">Saldo</TableCell>
            <TableCell align="right">Saldo (inkl. offener Ford.)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <RevenueOverviewRow key={row.timePeriodName} {...row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
