import React, { ReactElement } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

type TextInputProps = {
  label?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  helperText?: string;
  required?: boolean;
  validate?: any;
  valueFormatter?: (value: any) => string;
  readonly?: boolean;
} & TextFieldProps;

export const TextInput = ({
  label,
  fullWidth = true,
  disabled,
  required,
  helperText,
  validate,
  valueFormatter,
  readonly,
  ...props
}: TextInputProps): ReactElement => {
  return (
    <TextField
      style={{ pointerEvents: readonly ? 'none' : 'all' }}
      label={label}
      fullWidth={fullWidth}
      disabled={disabled}
      variant="outlined"
      size="small"
      helperText={helperText}
      {...props}
    />
  );
};
