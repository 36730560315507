import React, { ReactElement, useState } from 'react';
import {
  Grid,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { getMonth, getYear } from 'date-fns';

import { SelectInput } from '../components/inputs/SelectInput';
import { Page, PageProps } from '../components/layout/Page';
import { Currency } from '../components/outputs/Currency';
import { TransactionClassificationTable } from '../components/visuals/TransactionClassificationTable';
import { useDataStore } from '../hooks/dataStore';
import { useDateFormats } from '../hooks/useDateFormats';
import { useTemporalAggregation } from '../hooks/useTemporalAggregation';
import { useVatCalculations } from '../hooks/useVatCalculations';
import { monthOptions, yearOptions } from '../model/Options';
import { TransactionClassification } from '../model/TransactionClassification';

const DATE_FIELD = 'dateOfPayment';
const TEMPORAL_AGGREGATION_TYPE = 'MONTH';
export const VatPage = ({ title }: PageProps): ReactElement => {
  const [selectedMonth, setSelectedMonth] = useState<string>(
    getMonth(new Date()).toString(),
  );
  const [selectedYear, setSelectedYear] = useState<string>(
    getYear(new Date()).toString(),
  );

  const { toMonthName } = useDateFormats();
  const { transactionClassifications } = useDataStore();
  const transactionClassificationsByYear = transactionClassifications.filter(
    (tC) =>
      tC[DATE_FIELD] && getYear(tC[DATE_FIELD]).toString() === selectedYear,
  );
  const incomeTransactions = transactionClassificationsByYear.filter(
    (tC) => tC.type === 'INC',
  );
  const expenseTransactions = transactionClassificationsByYear.filter(
    (tC) => tC.type === 'EXP',
  );
  const monthlyIncomeAggregation = useTemporalAggregation(
    incomeTransactions,
    DATE_FIELD,
    TEMPORAL_AGGREGATION_TYPE,
  ).find((agg) => agg.aggregator === Number(selectedMonth));

  const monthlyExpenseAggregation = useTemporalAggregation(
    expenseTransactions,
    DATE_FIELD,
    TEMPORAL_AGGREGATION_TYPE,
  ).find((agg) => agg.aggregator === Number(selectedMonth));

  const {
    totalNetSuppliesAndServicesBasis,
    intraCommunitySuppliesBasis,
    suppliesAndServicesBasis20,
    suppliesAndServicesBasis10,
    suppliesAndServicesVatAmount20,
    suppliesAndServicesVatAmount10,
    intraCommunityAcquisitionsBasis,
    intraCommunityAcquisitionsBasis10,
    intraCommunityAcquisitionsBasis20,
    intraCommunityAcquisitionsVatAmount10,
    intraCommunityAcquisitionsVatAmount20,
    inputTaxesAmount,
    inputTaxesIntraCommunityAcquisitionsAmount,
    taxPayable,
  } = useVatCalculations(
    monthlyIncomeAggregation?.transactionClassifications ?? [],
    monthlyExpenseAggregation?.transactionClassifications ?? [],
  );

  const columns: Array<keyof TransactionClassification> = [
    'dateOfInvoice',
    'dateOfPayment',
    'receiptError',
    'businessPartner',
    'netAmount',
    'vatPercent',
    'vatAmount',
    'grossAmount',
    'vatId',
    'reverseCharge',
    'urlToReceipt',
  ];
  return (
    <Page title={title}>
      <Stack gap={4}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <SelectInput
              label="Monat"
              options={monthOptions}
              value={selectedMonth}
              onChange={(event) =>
                setSelectedMonth(event.target.value as string)
              }
            />
          </Grid>
          <Grid item lg={1} xs={3}>
            <SelectInput
              label="Jahr"
              options={yearOptions}
              value={selectedYear}
              onChange={(event) =>
                setSelectedYear(event.target.value as string)
              }
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={6} xs={12}>
            <Typography variant="h6">
              Umsatzsteuer {toMonthName(Number(selectedMonth))}
            </Typography>
            <Table>
              <TableHead>
                <TableCell></TableCell>
                <TableCell align="right">Bemessung</TableCell>
                <TableCell></TableCell>
                <TableCell align="right">USt.</TableCell>
              </TableHead>
              <TableRow>
                <TableCell>
                  Gesamtbetrag der Bemessungsgrundlage (ohne USt.)
                </TableCell>
                <TableCell align="right">
                  <Currency>{totalNetSuppliesAndServicesBasis}</Currency>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  &nbsp;&nbsp;&nbsp;davon Innergemeinschaftliche Lieferungen
                </TableCell>
                <TableCell align="right">
                  <Currency>{intraCommunitySuppliesBasis}</Currency>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  &nbsp;&nbsp;&nbsp;davon 20% Normalsteuersatz
                </TableCell>
                <TableCell align="right">
                  <Currency>{suppliesAndServicesBasis20}</Currency>
                </TableCell>
                <TableCell align="right">+</TableCell>
                <TableCell align="right">
                  <Currency>{suppliesAndServicesVatAmount20}</Currency>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  &nbsp;&nbsp;&nbsp;davon 10% erm. Steuersatz
                </TableCell>
                <TableCell align="right">
                  <Currency>{suppliesAndServicesBasis10}</Currency>
                </TableCell>
                <TableCell align="right">+</TableCell>
                <TableCell align="right">
                  <Currency>{suppliesAndServicesVatAmount10}</Currency>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Innergemeinschaftliche Erwerbe</TableCell>
                <TableCell align="right">
                  <Currency>{intraCommunityAcquisitionsBasis}</Currency>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  &nbsp;&nbsp;&nbsp;davon 20% Normalsteuersatz
                </TableCell>
                <TableCell align="right">
                  <Currency>{intraCommunityAcquisitionsBasis20}</Currency>
                </TableCell>
                <TableCell align="right">+</TableCell>
                <TableCell align="right">
                  <Currency>{intraCommunityAcquisitionsVatAmount20}</Currency>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  &nbsp;&nbsp;&nbsp;davon 10% erm. Steuersatz
                </TableCell>
                <TableCell align="right">
                  <Currency>{intraCommunityAcquisitionsBasis10}</Currency>
                </TableCell>
                <TableCell align="right">+</TableCell>
                <TableCell align="right">
                  <Currency>{intraCommunityAcquisitionsVatAmount10}</Currency>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Gesamtbetrag der Vorsteuern</TableCell>
                <TableCell></TableCell>
                <TableCell align="right">-</TableCell>
                <TableCell align="right">
                  <Currency>{inputTaxesAmount}</Currency>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Vorsteuern aus Innergemeinschaftlichen Erwerb
                </TableCell>
                <TableCell></TableCell>
                <TableCell align="right">-</TableCell>
                <TableCell align="right">
                  <Currency>
                    {inputTaxesIntraCommunityAcquisitionsAmount}
                  </Currency>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>
                  USt. Zahllast
                </TableCell>
                <TableCell></TableCell>
                <TableCell align="right">=</TableCell>
                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                  <Currency>{taxPayable}</Currency>
                </TableCell>
              </TableRow>
            </Table>
          </Grid>
        </Grid>
        <Stack>
          <Stack gap={2} paddingY={4}>
            <Typography variant="h4">
              {toMonthName(Number(selectedMonth))}
            </Typography>

            <TransactionClassificationTable
              transactionClassifications={[]}
              columns={columns}
            />
            <Stack gap={0}>
              <Typography variant="h6">Einnahmen</Typography>
              <TransactionClassificationTable
                transactionClassifications={
                  monthlyIncomeAggregation?.transactionClassifications ?? []
                }
                columns={columns}
                hideHeader={true}
              />
            </Stack>
            <Stack gap={0}>
              <Typography variant="h6">Ausgaben</Typography>
              <TransactionClassificationTable
                transactionClassifications={
                  monthlyExpenseAggregation?.transactionClassifications ?? []
                }
                columns={columns}
                hideHeader={true}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Page>
  );
};
