import React, { ReactElement } from 'react';

import { TransactionClassification } from '../../model/TransactionClassification';
import { parseTransactionClassifications } from '../../parser/TransactionClassificationParser';
import { parseCsv } from './csvUtil';
import { GitlabFileDownload } from './GitlabFileDownload';

type TransactionClassificationGitlabProps = {
  onChange: (transactionClassifications: TransactionClassification[]) => void;
};

export const TransactionClassificationGitlab = ({
  onChange,
}: TransactionClassificationGitlabProps): ReactElement => {
  const queryParameters = new URLSearchParams(window.location.search);
  const privateAccessToken = queryParameters.get('pt') ?? '';
  const repositoryId = queryParameters.get('repid') ?? '';
  const filePath = queryParameters.get('path') ?? '';
  const reference = queryParameters.get('ref') ?? '';

  return (
    <GitlabFileDownload
      prefilledFormValue={{
        filePath: decodeURIComponent(filePath),
        reference,
        repositoryId,
        privateAccessToken,
      }}
      onComplete={async (rawCsvString) => {
        const csvData = await parseCsv(rawCsvString);
        const results = parseTransactionClassifications(csvData);
        onChange(results);
      }}
    ></GitlabFileDownload>
  );
};
