import { TransactionClassification } from '../model/TransactionClassification';

type VatCalculationResult = {
  totalNetSuppliesAndServicesBasis: number;
  intraCommunitySuppliesBasis: number;
  suppliesAndServicesBasis20: number;
  suppliesAndServicesBasis10: number;
  suppliesAndServicesVatAmount20: number;
  suppliesAndServicesVatAmount10: number;

  intraCommunityAcquisitionsBasis: number;
  intraCommunityAcquisitionsBasis20: number;
  intraCommunityAcquisitionsBasis10: number;
  intraCommunityAcquisitionsVatAmount20: number;
  intraCommunityAcquisitionsVatAmount10: number;

  inputTaxesAmount: number;
  inputTaxesIntraCommunityAcquisitionsAmount: number;

  taxPayable: number;
};

const sumByField = (
  fieldName: keyof TransactionClassification,
  transactions: TransactionClassification[],
): number => {
  return transactions
    .map((tC) => tC[fieldName] as number)
    .reduce((prev, current) => prev + current, 0);
};
const VAT_20 = 20;
const VAT_10 = 10;
export const useVatCalculations = (
  incomeTransactions: TransactionClassification[],
  expensesTransactions: TransactionClassification[],
): VatCalculationResult => {
  const totalNetSuppliesAndServicesBasis = sumByField(
    'netAmount',
    incomeTransactions,
  );
  const intraCommunitySuppliesBasis = sumByField(
    'netAmount',
    incomeTransactions.filter((tC) => tC.reverseCharge),
  );
  const suppliesAndServicesBasis20 = sumByField(
    'netAmount',
    incomeTransactions.filter((tC) => tC.vatPercent === VAT_20),
  );
  const suppliesAndServicesBasis10 = sumByField(
    'netAmount',
    incomeTransactions.filter((tC) => tC.vatPercent === VAT_10),
  );
  const suppliesAndServicesVatAmount20 = sumByField(
    'vatAmount',
    incomeTransactions.filter((tC) => tC.vatPercent === VAT_20),
  );
  const suppliesAndServicesVatAmount10 = sumByField(
    'vatAmount',
    incomeTransactions.filter((tC) => tC.vatPercent === VAT_10),
  );

  const intraCommunityAcquisitionsBasis = sumByField(
    'netAmount',
    expensesTransactions.filter((tC) => tC.reverseCharge),
  );
  const intraCommunityAcquisitionsBasis20 = sumByField(
    'netAmount',
    expensesTransactions.filter(
      (tC) => tC.reverseCharge && tC.vatAmount !== 10,
    ),
  );
  const intraCommunityAcquisitionsBasis10 = sumByField(
    'netAmount',
    expensesTransactions.filter(
      (tC) => tC.reverseCharge && tC.vatAmount === 10,
    ),
  );
  const intraCommunityAcquisitionsVatAmount20 =
    (intraCommunityAcquisitionsBasis20 * VAT_20) / 100;
  const intraCommunityAcquisitionsVatAmount10 =
    (intraCommunityAcquisitionsBasis10 * VAT_10) / 100;

  const inputTaxesAmount = sumByField(
    'vatAmount',
    expensesTransactions.filter((tC) => !tC.reverseCharge),
  );
  const inputTaxesIntraCommunityAcquisitionsAmount =
    intraCommunityAcquisitionsVatAmount10 +
    intraCommunityAcquisitionsVatAmount20;

  const taxPayable =
    suppliesAndServicesVatAmount10 +
    suppliesAndServicesVatAmount20 +
    intraCommunityAcquisitionsVatAmount20 +
    intraCommunityAcquisitionsVatAmount10 -
    inputTaxesAmount -
    inputTaxesIntraCommunityAcquisitionsAmount;

  return {
    inputTaxesAmount,
    inputTaxesIntraCommunityAcquisitionsAmount,
    intraCommunityAcquisitionsBasis,
    intraCommunityAcquisitionsBasis10,
    intraCommunityAcquisitionsBasis20,
    intraCommunityAcquisitionsVatAmount10,
    intraCommunityAcquisitionsVatAmount20,
    intraCommunitySuppliesBasis,
    suppliesAndServicesBasis10,
    suppliesAndServicesBasis20,
    suppliesAndServicesVatAmount10,
    suppliesAndServicesVatAmount20,
    totalNetSuppliesAndServicesBasis,
    taxPayable,
  };
};
