import { getYear } from 'date-fns';

import { Option } from '../components/inputs/SelectInput';
import { toMonthName } from '../hooks/useDateFormats';
import { TemporalAggregationType } from '../hooks/useTemporalAggregation';

export const aggregationTypeOptions: Array<Option<TemporalAggregationType>> = [
  {
    label: 'Monatlich',
    value: 'MONTH',
  },
  {
    label: 'Quartal',
    value: 'QUATER',
  },
  {
    label: 'Jahr',
    value: 'YEAR',
  },
];

export const monthOptions: Option[] = [...new Array(12)].map(
  (value, index) => ({
    value: index.toString(),
    label: toMonthName(index),
  }),
);

export const yearOptions: Option[] = [...new Array(2050)]
  .map((value, index) => ({
    value: index.toString(),
    label: index.toString(),
  }))
  .filter(
    (option) =>
      Number(option.value) > 2019 &&
      Number(option.value) < getYear(new Date()) + 1,
  );

export type TransactionDateType = 'dateOfPayment' | 'dateOfInvoice';
export const dateTypeOptions: Array<Option<TransactionDateType>> = [
  {
    label: 'Rechnungsdatum',
    value: 'dateOfInvoice',
  },
  {
    label: 'Zahlungsdatum',
    value: 'dateOfPayment',
  },
];
