import React, { ReactElement } from 'react';

import { DataPage } from '../pages/DataPage';
import { PrivateWithdrawalsPage } from '../pages/PrivateWithdrawalsPage';
import { RevenueOverviewPage } from '../pages/RevenueOverviewPage';
import { RevenuePage } from '../pages/RevenuePage';
import { VatPage } from '../pages/VatPage';
import { useDataStore } from './dataStore';

type MenuItemData = {
  label: string;
  path: string;
  element: ReactElement;
  dataNeccessary: boolean;
};

const routes: MenuItemData[] = [
  {
    label: 'Daten laden',
    path: '/data',
    element: <DataPage title={'Daten laden'} />,
    dataNeccessary: false,
  },
  {
    label: 'Umsatzübersicht',
    path: '/revenueoverview',
    element: <RevenueOverviewPage title={'Umsätzeübersicht'} />,
    dataNeccessary: true,
  },
  {
    label: 'Umsätze',
    path: '/revenue',
    element: <RevenuePage title={'Umsätze'} />,
    dataNeccessary: true,
  },
  {
    label: 'Umsatzsteuer',
    path: '/vat',
    element: <VatPage title={'Umsatzsteuer'} />,
    dataNeccessary: true,
  },
  {
    label: 'Privatentnahmen',
    path: '/withdrawals',
    element: <PrivateWithdrawalsPage title={'Privatentnahmen'} />,
    dataNeccessary: true,
  },
];

export const useRoutes = (): MenuItemData[] => {
  const { transactionClassifications } = useDataStore();
  if (transactionClassifications.length === 0) {
    return routes.filter((r) => !r.dataNeccessary);
  }
  return routes;
};
