import React, { PropsWithChildren, ReactElement } from 'react';
import { Box, Grid, Stack, Tab, Tabs } from '@mui/material';
import Button from '@mui/material/Button';

import { Page, PageProps } from '../components/layout/Page';
import { TransactionClassificationFileUpload } from '../components/parser/TransactionClassificationFileUpload';
import { TransactionClassificationGitlab } from '../components/parser/TransactionClassificationGitlab';
import { useDataStore } from '../hooks/dataStore';
import { TransactionClassification } from '../model/TransactionClassification';

export const DataPage = ({ title }: PageProps): ReactElement => {
  const { replaceAll, reset } = useDataStore();

  return (
    <Page title={title}>
      <Stack spacing={5}>
        <DataUploadTabs replaceAll={replaceAll} />
        <Grid container>
          {/* <Grid item xs={12}> */}
          {/*  <Typography> */}
          {/*    Aktuell sind {transactionClassifications.length} Datensätze */}
          {/*    importiert. */}
          {/*  </Typography> */}
          {/* </Grid> */}
          <Grid item xs={12}>
            <Button onClick={() => reset()} color="error" variant="outlined">
              Reset Daten
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </Page>
  );
};

type DataUploadTabsProps = {
  replaceAll: (transactionClassifications: TransactionClassification[]) => void;
};
const DataUploadTabs = ({ replaceAll }: DataUploadTabsProps): ReactElement => {
  const [value, setValue] = React.useState(0);

  return (
    <Grid container>
      <Grid item lg={4} md={6} xs={12}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={(event, childIndex) => setValue(childIndex)}
            >
              <Tab label="Gitlab import" />
              <Tab label="Datei upload" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <TransactionClassificationGitlab onChange={replaceAll} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TransactionClassificationFileUpload onChange={replaceAll} />
          </TabPanel>
        </Box>
      </Grid>
    </Grid>
  );
};

type TabPanelProps = {
  value: number;
  index: number;
};

const TabPanel = ({
  value,
  index,
  children,
}: PropsWithChildren<TabPanelProps>): ReactElement => {
  return (
    <Box hidden={value !== index} paddingY={2}>
      {children}
    </Box>
  );
};
