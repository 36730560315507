import Papa, { ParseResult } from 'papaparse';

export const parseCsv = async <T = any>(file: string | File): Promise<T[]> => {
  return await new Promise((resolve, reject) => {
    Papa.parse<T>(file, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true,
      complete: (results: ParseResult<T>) => {
        const data = results.data;
        resolve(data);
      },
    });
  });
};
