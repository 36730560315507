import React, { ReactElement, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

type ShowDetailsCheckboxResult = {
  displayDetails: boolean;
  component: ReactElement;
};

export const useShowDetailsCheckbox = (
  initialState?: boolean,
): ShowDetailsCheckboxResult => {
  const [displayDetails, setDisplayDetails] = useState(initialState ?? false);
  const component = (
    <UseShowDetailsCheckbox
      displayDetails={displayDetails}
      setDisplayDetails={setDisplayDetails}
    />
  );
  return { displayDetails, component };
};

type UseShowDetailsCheckboxProps = {
  displayDetails: boolean;
  setDisplayDetails: (checked: boolean) => void;
};

const UseShowDetailsCheckbox = ({
  displayDetails,
  setDisplayDetails,
}: UseShowDetailsCheckboxProps): ReactElement => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            defaultChecked
            checked={displayDetails}
            onChange={(e) => setDisplayDetails(e.target.checked)}
          />
        }
        label="Details anzeigen"
      />
    </FormGroup>
  );
};
