import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { TransactionClassification } from '../model/TransactionClassification';

interface DataState {
  transactionClassifications: TransactionClassification[];
  replaceAll: (transactionClassifications: TransactionClassification[]) => void;
  reset: () => void;
}

const dataStoreState = create<DataState>()(
  devtools(
    (set) => ({
      transactionClassifications: [],
      replaceAll: (by) => set((state) => ({ transactionClassifications: by })),
      reset: () => set(() => ({ transactionClassifications: [] })),
    }),
    {
      name: 'data-storage',
    },
  ),
);

export const useDataStore = (): DataState => {
  const { replaceAll, reset, transactionClassifications } = dataStoreState();
  return { replaceAll, reset, transactionClassifications };
};
