import { format } from 'date-fns';
import { deAT } from 'date-fns/locale';

export type DateFormats = {
  toQuarterName: (quarterValue: number | Date) => string;
  toMonthName: (monthValue: number | Date) => string;
  toDateString: (date: Date) => string;
};

const toDateString = (date: Date): string => {
  return format(date, 'P', { locale: deAT });
};
export const toMonthName = (monthValue: number | Date): string => {
  const pattern = 'MMMM';
  if (typeof monthValue === 'number') {
    const date = new Date();
    date.setMonth(monthValue);
    return format(date, pattern, { locale: deAT });
  }
  return format(monthValue, pattern, { locale: deAT });
};

export const toQuarterName = (quarterValue: number | Date): string => {
  const pattern = 'QQQQ';
  if (typeof quarterValue === 'number') {
    const date = new Date();
    date.setMonth(quarterValue * 3 - 1);
    return format(date, pattern, { locale: deAT });
  }
  return format(quarterValue, pattern, { locale: deAT });
};
export const useDateFormats = (): DateFormats => {
  return {
    toQuarterName,
    toMonthName,
    toDateString,
  };
};
