export type TransactionClassificationType = 'INC' | 'EXP' | 'INC_NE' | 'EXP_NE';
export type PaymentType = 'BANK_TRANSFER' | 'CASH';

export const shareholderCostCenterKeys = [
  'PRIV_GH',
  'PRIV_HW',
  'PRIV_JS',
] as const;
export type ShareholderCostCenterKey = typeof shareholderCostCenterKeys[number];

type TransactionClassificationRequired = {
  type: TransactionClassificationType;
  grossAmount: number;
  vatPercent: number;
  vatAmount: number;
  netAmount: number;
  costType: string;
  costCenter: string;
  businessPartner: string;
  paymentType: PaymentType;
};

type TransactionClassificationOptional = {
  dateOfInvoice?: Date;

  dateOfPayment?: Date;
  receiptId?: string;
  urlToReceipt?: string;
  nameOfDocument?: string;
  description?: string;
  referenceReceiptId?: string;
  accountingNumber?: number;
  usefulLifeInMonths?: number;
  receiptError?: boolean;
  vatId?: string;
  reverseCharge?: boolean;
  // ISO 4217
  receiptCurrencyCode?: string;
  receiptCurrencyAmount?: number;
  exchangeRate?: number;
  // ISO 3166-1 Alpha-3 code
  thirdPartyCountryCode?: string;
  workingHours?: number;
  netHourlyRate?: number;
  bankingAccount?: string;
};

export type TransactionClassification = TransactionClassificationRequired &
  TransactionClassificationOptional;

export const requiredCsvKeys = [
  'type',
  'dateOfInvoice',
  'grossAmount',
  'vatPercent',
  'vatAmount',
  'netAmount',
  'costType',
  'costCenter',
  'usefulLifeInMonths',
  'businessPartner',
  'paymentType',
] as const;

export const isParsable = (
  data?: unknown,
): data is TransactionClassification => {
  if (typeof data !== 'object' || data == null) {
    return false;
  }
  return requiredCsvKeys.every((csvKey) => csvKey in data);
};
