import React, { ReactElement } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { useRoutes } from './hooks/useRoutes';
import { DataPage } from './pages/DataPage';

function App(): ReactElement {
  const routes = useRoutes();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DataPage />} />
        {routes.map((route) => {
          return (
            <Route key={route.path} path={route.path} element={route.element} />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
}
export default App;
