import React, { PropsWithChildren, ReactElement } from 'react';

type PercentProps = {
  digits?: number;
};

export const Percent = ({
  children,
  digits,
}: PropsWithChildren<PercentProps>): ReactElement => {
  const percentFormat = new Intl.NumberFormat('de-AT', {
    style: 'percent',
    minimumFractionDigits: digits != null ? digits : 2,
    maximumFractionDigits: digits != null ? digits : 2,
  });
  if (children == null || isNaN(children as number)) {
    return <>{children}</>;
  }
  return <>{percentFormat.format(children as number)}</>;
};
