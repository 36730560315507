import { getMonth, getQuarter, getYear } from 'date-fns';
import { groupBy } from 'lodash';

import { TransactionClassification } from '../model/TransactionClassification';
import { toMonthName, toQuarterName } from './useDateFormats';

export type TemporalAggregationType = 'MONTH' | 'QUATER' | 'YEAR';

export type AggregationResult = {
  aggregator: number;
  transactionClassifications: TransactionClassification[];
  total: number;
  temporalAggregationType: TemporalAggregationType;
};
export const useTemporalAggregation = (
  transactionClassifications: TransactionClassification[],
  dateField: 'dateOfInvoice' | 'dateOfPayment',
  aggregationType: TemporalAggregationType,
): AggregationResult[] => {
  const aggregate = (
    aggregatorFn: (date: Date | number) => number,
  ): AggregationResult[] => {
    return Object.entries(
      groupBy(transactionClassifications, (item) => {
        const value = item[dateField];
        if (value != null) {
          return aggregatorFn(value);
        }
        return undefined;
      }),
    ).map(([aggregationValue, transactionClassifications]) => {
      return {
        total: transactionClassifications.reduce(
          (sum, transactionClassification) =>
            transactionClassification.netAmount + sum,
          0,
        ),
        aggregator: Number(aggregationValue),
        transactionClassifications,
        temporalAggregationType: aggregationType,
      };
    });
  };

  switch (aggregationType) {
    case 'MONTH':
      return aggregate(getMonth);
    case 'QUATER':
      return aggregate(getQuarter);
    case 'YEAR':
      return aggregate(getYear);
  }
};

export const toTemporalAggregation = (
  transactionClassifications: TransactionClassification[],
  dateField: 'dateOfInvoice' | 'dateOfPayment',
  aggregationType: TemporalAggregationType,
): AggregationResult[] => {
  const aggregate = (
    aggregatorFn: (date: Date | number) => number,
  ): AggregationResult[] => {
    return Object.entries(
      groupBy(transactionClassifications, (item) => {
        const value = item[dateField];
        if (value != null) {
          return aggregatorFn(value);
        }
        return undefined;
      }),
    ).map(([aggregationValue, transactionClassifications]) => {
      return {
        total: transactionClassifications.reduce(
          (sum, transactionClassification) =>
            transactionClassification.netAmount + sum,
          0,
        ),
        aggregator: Number(aggregationValue),
        transactionClassifications,
        temporalAggregationType: aggregationType,
      };
    });
  };

  switch (aggregationType) {
    case 'MONTH':
      return aggregate(getMonth);
    case 'QUATER':
      return aggregate(getQuarter);
    case 'YEAR':
      return aggregate(getYear);
  }
};

export const toTimePeriodName = (
  timePeriodValue: number,
  temporalAggregationType: TemporalAggregationType,
): string => {
  switch (temporalAggregationType) {
    case 'MONTH':
      return toMonthName(timePeriodValue);
    case 'QUATER':
      return toQuarterName(timePeriodValue);
    case 'YEAR':
      return timePeriodValue.toString();
  }
};
