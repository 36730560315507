import React, { ReactElement } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Table, TableCell, TableHead, TableRow } from '@mui/material';
import { sum } from 'lodash';

import { DateFormats, useDateFormats } from '../../hooks/useDateFormats';
import { TransactionClassification } from '../../model/TransactionClassification';
import { Currency } from '../outputs/Currency';
import { Percent } from '../outputs/Percent';
import { YesNo } from '../outputs/YesNo';

type TransactionClassificationTableProps = {
  transactionClassifications: TransactionClassification[];
  columns: Array<keyof TransactionClassification>;
  hideHeader?: boolean;
  sumColumns?: Array<keyof TransactionClassification>;
  hideEntries?: boolean;
};

export const TransactionClassificationTable = ({
  transactionClassifications,
  columns,
  hideHeader,
  sumColumns,
  hideEntries,
}: TransactionClassificationTableProps): ReactElement => {
  const dateFormats = useDateFormats();
  return (
    <Table style={{ tableLayout: 'fixed' }}>
      {!hideHeader && (
        <TableHead>
          {columns.map((columnKey) => {
            const columnConfig = columnConfigs.find(
              (columnConfig) => columnConfig.key === columnKey,
            );
            return columnConfig != null ? columnConfig.headerCell : null;
          })}
        </TableHead>
      )}
      {!hideEntries &&
        transactionClassifications.map((tC, index) => {
          return (
            <TableRow key={index}>
              {columns.map((columnKey) => {
                const columnConfig = columnConfigs.find(
                  (columnConfig) => columnConfig.key === columnKey,
                );
                return columnConfig != null
                  ? columnConfig.columnCell(tC, dateFormats)
                  : null;
              })}
            </TableRow>
          );
        })}
      {sumColumns && sumColumns.length > 0 && (
        <TableRow>
          {columns?.map((columnKey, index) => {
            if (
              sumColumns?.includes(columnKey) &&
              index === columns.indexOf(columnKey)
            ) {
              return (
                <TableCell
                  key={columnKey}
                  align="right"
                  style={{ fontWeight: 'bold' }}
                >
                  <Currency>
                    {sum(transactionClassifications.map((tC) => tC[columnKey]))}
                  </Currency>
                </TableCell>
              );
            } else {
              return <TableCell key={columnKey}></TableCell>;
            }
          })}
        </TableRow>
      )}
    </Table>
  );
};

type TransactionClassificationColumnConfig = {
  key: keyof TransactionClassification;
  headerCell: ReactElement;
  columnCell: (
    transactionClassification: TransactionClassification,
    dateFormats: DateFormats,
  ) => ReactElement;
};

const columnConfigs: TransactionClassificationColumnConfig[] = [
  {
    key: 'dateOfInvoice',
    headerCell: <TableCell>Rechnungsdatum</TableCell>,
    columnCell: (transactionClassification, dateFormats) => (
      <TableCell>
        {transactionClassification.dateOfInvoice != null
          ? dateFormats.toDateString(transactionClassification.dateOfInvoice)
          : '-'}
      </TableCell>
    ),
  },
  {
    key: 'dateOfPayment',
    headerCell: <TableCell>Zahlungsdatum</TableCell>,
    columnCell: (transactionClassification, dateFormats) => (
      <TableCell>
        {transactionClassification.dateOfPayment != null
          ? dateFormats.toDateString(transactionClassification.dateOfPayment)
          : '-'}
      </TableCell>
    ),
  },
  {
    key: 'receiptError',
    headerCell: <TableCell>Rechnung fehlerhaft?</TableCell>,
    columnCell: (transactionClassification) => (
      <TableCell>
        <YesNo>{transactionClassification?.receiptError}</YesNo>
      </TableCell>
    ),
  },
  {
    key: 'businessPartner',
    headerCell: <TableCell>Handelspartner</TableCell>,
    columnCell: (transactionClassification) => (
      <TableCell>{transactionClassification.businessPartner}</TableCell>
    ),
  },
  {
    key: 'costType',
    headerCell: <TableCell>Kostenart</TableCell>,
    columnCell: (transactionClassification) => (
      <TableCell>{transactionClassification.costType}</TableCell>
    ),
  },
  {
    key: 'netAmount',
    headerCell: <TableCell align="right">Nettobetrag</TableCell>,
    columnCell: (transactionClassification) => (
      <TableCell align="right">
        <Currency>{transactionClassification.netAmount}</Currency>
      </TableCell>
    ),
  },
  {
    key: 'vatPercent',
    headerCell: <TableCell align="right">USt.-Satz</TableCell>,
    columnCell: (transactionClassification) => (
      <TableCell align="right">
        <Percent digits={0}>
          {transactionClassification.vatPercent / 100}
        </Percent>
      </TableCell>
    ),
  },
  {
    key: 'vatAmount',
    headerCell: <TableCell align="right">USt.</TableCell>,
    columnCell: (transactionClassification) => (
      <TableCell align="right">
        <Currency>{transactionClassification.vatAmount}</Currency>
      </TableCell>
    ),
  },
  {
    key: 'grossAmount',
    headerCell: <TableCell align="right">Bruttobetrag</TableCell>,
    columnCell: (transactionClassification) => (
      <TableCell align="right">
        <Currency>{transactionClassification.grossAmount}</Currency>
      </TableCell>
    ),
  },
  {
    key: 'reverseCharge',
    headerCell: <TableCell>Reverse Charge?</TableCell>,
    columnCell: (transactionClassification) => (
      <TableCell>
        <YesNo>{transactionClassification.reverseCharge}</YesNo>
      </TableCell>
    ),
  },
  {
    key: 'thirdPartyCountryCode',
    headerCell: <TableCell>Drittland</TableCell>,
    columnCell: (transactionClassification) => (
      <TableCell>{transactionClassification.thirdPartyCountryCode}</TableCell>
    ),
  },
  {
    key: 'vatId',
    headerCell: <TableCell>UID</TableCell>,
    columnCell: (transactionClassification) => (
      <TableCell>{transactionClassification.vatId}</TableCell>
    ),
  },
  {
    key: 'description',
    headerCell: <TableCell>Beschreibung</TableCell>,
    columnCell: (transactionClassification) => (
      <TableCell>{transactionClassification.description}</TableCell>
    ),
  },
  {
    key: 'accountingNumber',
    headerCell: <TableCell align="right">Konto (Buchhaltung)</TableCell>,
    columnCell: (transactionClassification) => (
      <TableCell align="right">
        {transactionClassification.accountingNumber}
      </TableCell>
    ),
  },
  {
    key: 'costCenter',
    headerCell: <TableCell align="right">Kostenstelle</TableCell>,
    columnCell: (transactionClassification) => (
      <TableCell align="right">
        {transactionClassification.costCenter}
      </TableCell>
    ),
  },
  {
    key: 'nameOfDocument',
    headerCell: <TableCell align="right">Dokumentname</TableCell>,
    columnCell: (transactionClassification) => (
      <TableCell align="right">
        <a
          href={transactionClassification.urlToReceipt}
          target="_blank"
          rel="noreferrer"
        >
          {transactionClassification.nameOfDocument}
        </a>
      </TableCell>
    ),
  },
  {
    key: 'urlToReceipt',
    headerCell: <TableCell align="right">Dokument</TableCell>,
    columnCell: (transactionClassification) => (
      <TableCell align="right">
        {transactionClassification.urlToReceipt && (
          <a
            href={transactionClassification.urlToReceipt}
            target="_blank"
            rel="noreferrer"
          >
            <OpenInNewIcon />
          </a>
        )}
      </TableCell>
    ),
  },
];
