import React, { ReactElement } from 'react';
import { Grid, Stack, Typography } from '@mui/material';

import { useShowDetailsCheckbox } from '../components/custom-inputs/useShowDetailsCheckbox';
import { Page, PageProps } from '../components/layout/Page';
import { Currency } from '../components/outputs/Currency';
import { TransactionClassificationTable } from '../components/visuals/TransactionClassificationTable';
import { useDataStore } from '../hooks/dataStore';
import {
  PrivateWithdrawalsShareholderResult,
  toPrivateWithdrawalsCalculations,
} from '../hooks/usePrivateWithdrawalsCalculations';
import { AggregationResult } from '../hooks/useTemporalAggregation';
import { yearOptions } from '../model/Options';
import {
  shareholderCostCenterKeys,
  TransactionClassification,
} from '../model/TransactionClassification';

export const PrivateWithdrawalsPage = ({ title }: PageProps): ReactElement => {
  const { displayDetails, component: ShowDetailsCheckbox } =
    useShowDetailsCheckbox(true);
  const { transactionClassifications } = useDataStore();

  const result = toPrivateWithdrawalsCalculations(transactionClassifications);

  return (
    <Page title={title}>
      {ShowDetailsCheckbox}
      <Grid container columnSpacing={8}>
        {shareholderCostCenterKeys.map((shareholderCostCenterKey) => (
          <Grid
            item
            lg={4}
            xs={12}
            textAlign="center"
            key={shareholderCostCenterKey}
          >
            <Typography variant="h4">{shareholderCostCenterKey}</Typography>
          </Grid>
        ))}
      </Grid>
      {yearOptions.map((option) => {
        const year = Number(option.value);
        return (
          <Grid container key={year} columnSpacing={8} marginBottom={6}>
            <Grid
              item
              xs={12}
              color="white"
              sx={(theme) => ({
                backgroundColor: theme.palette.primary.light,
              })}
            >
              <Typography variant="h6">{year}</Typography>
            </Grid>
            {shareholderCostCenterKeys.map((shareholderCostCenterKey) => (
              <Grid item lg={4} xs={12} key={shareholderCostCenterKey}>
                <ShareholderBlock
                  shareholderAggregations={
                    result.find(
                      (s) =>
                        s.shareholderCostCenterKey === shareholderCostCenterKey,
                    )!
                  }
                  aggregator={year}
                  displayDetails={displayDetails}
                />
              </Grid>
            ))}
          </Grid>
        );
      })}
    </Page>
  );
};

type ShareholderBlockProps = {
  shareholderAggregations: PrivateWithdrawalsShareholderResult;
  aggregator: number;
  displayDetails?: boolean;
};

const ShareholderBlock = ({
  shareholderAggregations,
  aggregator,
  displayDetails,
}: ShareholderBlockProps): ReactElement => {
  const withdrawalsTotal =
    shareholderAggregations.withdrawalAggregations.find(
      (s) => s.aggregator === aggregator,
    )?.total ?? 0;
  const companyExpenses =
    shareholderAggregations.companyExpensesPaidByPrivateAccountAggregations.find(
      (s) => s.aggregator === aggregator,
    )?.total ?? 0;
  const total = withdrawalsTotal - companyExpenses;
  return (
    <>
      <WithdrawalsTable
        aggregations={shareholderAggregations.withdrawalAggregations}
        aggregator={aggregator}
        displayDetails={displayDetails}
      />
      <ExpensesPaidByShareholderBankAccount
        aggregations={
          shareholderAggregations.companyExpensesPaidByPrivateAccountAggregations
        }
        aggregator={aggregator}
        displayDetails={displayDetails}
      />
      <Grid container paddingX={2}>
        <Grid item xs={6} fontWeight="bold">
          Gesamt
        </Grid>
        <Grid item xs={6} container justifyContent={'right'} fontWeight="bold">
          <Currency>{total}</Currency>
        </Grid>
      </Grid>
    </>
  );
};

type WithdrawalsTableProps = {
  aggregations: AggregationResult[];
  aggregator: number;
  displayDetails?: boolean;
};

const WithdrawalsTable = ({
  aggregations,
  aggregator,
  displayDetails,
}: WithdrawalsTableProps): ReactElement => {
  const resultsByAggregator = aggregations.find(
    (agg) => agg?.aggregator === aggregator,
  );

  const columns: Array<keyof TransactionClassification> = [
    'dateOfPayment',
    'description',
    'netAmount',
  ];

  return (
    <Stack>
      <Stack>{displayDetails && <h3>Entnahmen</h3>}</Stack>
      <TransactionClassificationTable
        transactionClassifications={
          resultsByAggregator?.transactionClassifications ?? []
        }
        columns={columns}
        hideHeader={!displayDetails}
        hideEntries={!displayDetails}
      />
      <Stack direction="row" justifyContent="space-between" paddingX={2}>
        <Typography>Entnahmen</Typography>
        <Typography>
          <Currency>{resultsByAggregator?.total ?? 0}</Currency>
        </Typography>
      </Stack>
    </Stack>
  );
};

type ExpensesPaidByPrivateAccountProps = {
  aggregations: AggregationResult[];
  aggregator: number;
  displayDetails?: boolean;
};

const ExpensesPaidByShareholderBankAccount = ({
  aggregations,
  aggregator,
  displayDetails,
}: ExpensesPaidByPrivateAccountProps): ReactElement => {
  const resultsByAggregator = aggregations.find(
    (agg) => agg?.aggregator === aggregator,
  );
  const columns: Array<keyof TransactionClassification> = [
    'dateOfPayment',
    'description',
    'netAmount',
  ];
  return (
    <Stack>
      <Stack>{displayDetails && <h3>Firmenausgaben via Privatkonto</h3>}</Stack>
      <TransactionClassificationTable
        transactionClassifications={
          resultsByAggregator?.transactionClassifications ?? []
        }
        columns={columns}
        hideHeader={true}
        hideEntries={!displayDetails}
      />
      <Stack direction="row" justifyContent="space-between" paddingX={2}>
        <Typography>Firmenausgaben via Privatkonto</Typography>
        <Typography>
          <Currency>{(resultsByAggregator?.total ?? 0) * -1}</Currency>
        </Typography>
      </Stack>
    </Stack>
  );
};
