import React, { ChangeEvent, ReactElement } from 'react';
import Button from '@mui/material/Button';

type FileUploadInputProps = {
  label: string;
  accept: string;
  onComplete: (file: File | undefined) => void;
};

export const FileUploadInput = ({
  label,
  accept,
  onComplete,
}: FileUploadInputProps): ReactElement => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onComplete(event?.target?.files?.[0]);
  };
  return (
    <label htmlFor="upload-file">
      <input
        id="upload-file"
        type="file"
        name="upload-file"
        accept={accept}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
      <Button variant="contained" component="span">
        {label}
      </Button>
    </label>
  );
};
