import React, { ReactElement, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { getYear } from 'date-fns';

import { SelectInput } from '../components/inputs/SelectInput';
import { Page, PageProps } from '../components/layout/Page';
import { Currency } from '../components/outputs/Currency';
import { TransactionClassificationTable } from '../components/visuals/TransactionClassificationTable';
import { useDataStore } from '../hooks/dataStore';
import { useDateFormats } from '../hooks/useDateFormats';
import {
  AggregationResult,
  TemporalAggregationType,
  useTemporalAggregation,
} from '../hooks/useTemporalAggregation';
import {
  aggregationTypeOptions,
  dateTypeOptions,
  TransactionDateType,
  yearOptions,
} from '../model/Options';
import { TransactionClassification } from '../model/TransactionClassification';

export const RevenuePage = ({ title }: PageProps): ReactElement | null => {
  const [temporalAggregationType, setTemporalAggregationType] =
    useState<TemporalAggregationType>('MONTH');

  const [selectedYear, setSelectedYear] = useState<string>(
    getYear(new Date()).toString(),
  );

  const [selectedDateField, setSelectedDateField] =
    useState<TransactionDateType>('dateOfPayment');

  const [displayDetails, setDisplayDetails] = useState(false);

  const { toMonthName, toQuarterName } = useDateFormats();
  const { transactionClassifications } = useDataStore();

  const transactionClassificationsByYear = transactionClassifications.filter(
    (tC) => {
      const date = tC[selectedDateField];
      if (!date) {
        return false;
      }
      return getYear(date).toString() === selectedYear;
    },
  );
  const incomeTransactions = transactionClassificationsByYear.filter(
    (tC) => tC.type === 'INC',
  );
  const outstandingIncome = transactionClassifications.filter(
    (i) => i.dateOfPayment === undefined && i.type === 'INC',
  );
  const expenseTransactions = transactionClassificationsByYear.filter(
    (tC) => tC.type === 'EXP',
  );
  const incomeAggregation = useTemporalAggregation(
    incomeTransactions,
    selectedDateField,
    temporalAggregationType,
  );
  const expenseAggregation = useTemporalAggregation(
    expenseTransactions,
    selectedDateField,
    temporalAggregationType,
  );
  const outstandingIncomeAggregation = useTemporalAggregation(
    outstandingIncome,
    'dateOfInvoice',
    temporalAggregationType,
  );
  const aggregatorsList = Array.from(
    new Set(
      incomeAggregation
        .map((v) => v.aggregator)
        .concat(expenseAggregation.map((v) => v.aggregator)),
    ),
  ).sort((a, b) => a - b);

  const columns: Array<keyof TransactionClassification> = [
    'dateOfInvoice',
    'dateOfPayment',
    'description',
    'costCenter',
    'netAmount',
    'urlToReceipt',
  ];
  if (!transactionClassifications) {
    return null;
  }
  return (
    <Page title={title}>
      <Stack>
        <Grid container spacing={2}>
          <Grid item lg={2} xs={3}>
            <SelectInput
              label="Zeitliche Aggregation"
              options={aggregationTypeOptions}
              value={temporalAggregationType}
              onChange={(event) =>
                setTemporalAggregationType(
                  event.target.value as TemporalAggregationType,
                )
              }
            />
          </Grid>
          <Grid item lg={1} xs={3}>
            <SelectInput
              label="Jahr"
              options={yearOptions}
              value={selectedYear}
              onChange={(event) =>
                setSelectedYear(event.target.value as string)
              }
            />
          </Grid>
          <Grid item lg={2} xs={3}>
            <SelectInput
              label="Datumstyp"
              options={dateTypeOptions}
              value={selectedDateField}
              onChange={(event) =>
                setSelectedDateField(event.target.value as TransactionDateType)
              }
            />
          </Grid>
          <Grid item lg={2} xs={3}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    checked={displayDetails}
                    onChange={(e) => setDisplayDetails(e.target.checked)}
                  />
                }
                label="Details anzeigen"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Stack>
          {aggregatorsList
            .map((aggregator) => {
              const incomeAggregationResult = incomeAggregation.find(
                (agg) => agg?.aggregator === aggregator,
              );
              const expenseAggregationResult = expenseAggregation.find(
                (agg) => agg?.aggregator === aggregator,
              );
              const outstandingAggregationResult =
                outstandingIncomeAggregation.find(
                  (agg) => agg?.aggregator === aggregator,
                );
              const incomeLength =
                incomeAggregationResult?.transactionClassifications?.length ??
                0;
              const expensesLength =
                expenseAggregationResult?.transactionClassifications?.length ??
                0;
              if (incomeLength + expensesLength === 0) {
                return null;
              }
              return (
                <Stack key={aggregator} gap={2} paddingY={4}>
                  {temporalAggregationType === 'MONTH' && (
                    <Typography variant="h4">
                      {toMonthName(aggregator)}
                    </Typography>
                  )}
                  {temporalAggregationType === 'QUATER' && (
                    <Typography variant="h4">
                      {toQuarterName(aggregator)}
                    </Typography>
                  )}
                  {temporalAggregationType === 'YEAR' && (
                    <Typography variant="h4">Jahr {aggregator}</Typography>
                  )}
                  {displayDetails && (
                    <>
                      <TransactionClassificationTable
                        transactionClassifications={[]}
                        columns={columns}
                      />
                      <Stack gap={0}>
                        <Typography variant="h6">Einnahmen</Typography>
                        <TransactionClassificationTable
                          transactionClassifications={
                            incomeAggregationResult?.transactionClassifications ??
                            []
                          }
                          columns={columns}
                          hideHeader={true}
                        />
                      </Stack>
                      <Stack gap={0}>
                        <Typography variant="h6">Ausgaben</Typography>
                        <TransactionClassificationTable
                          transactionClassifications={
                            expenseAggregationResult?.transactionClassifications ??
                            []
                          }
                          columns={columns}
                          hideHeader={true}
                        />
                      </Stack>
                    </>
                  )}
                  <Stack alignItems="flex-end">
                    <Grid container justifyContent="flex-end">
                      <Grid item md={6} xs={0}></Grid>
                      <Grid
                        item
                        md={3}
                        xs={6}
                        container
                        justifyContent="flex-end"
                      >
                        <Typography variant="h6">Einnahmen gesamt</Typography>
                      </Grid>
                      <Grid
                        item
                        md={3}
                        xs={6}
                        container
                        justifyContent="flex-end"
                      >
                        <Typography variant="h6">
                          <Currency>
                            {incomeAggregationResult?.total ?? 0}
                          </Currency>
                        </Typography>
                      </Grid>

                      {selectedDateField === 'dateOfPayment' && (
                        <>
                          {' '}
                          <Grid item md={6} xs={0}></Grid>
                          <Grid
                            item
                            md={3}
                            xs={6}
                            container
                            justifyContent="flex-end"
                          >
                            <Typography variant="h6">
                              Offene Forderungen
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={3}
                            xs={6}
                            container
                            justifyContent="flex-end"
                          >
                            <Typography variant="h6">
                              <Currency>
                                {outstandingAggregationResult?.total ?? 0}
                              </Currency>
                            </Typography>
                          </Grid>
                        </>
                      )}

                      <Grid item md={6} xs={0}></Grid>
                      <Grid
                        item
                        md={3}
                        xs={6}
                        container
                        justifyContent="flex-end"
                      >
                        <Typography variant="h6">Ausgaben gesamt</Typography>
                      </Grid>
                      <Grid
                        item
                        md={3}
                        xs={6}
                        container
                        justifyContent="flex-end"
                      >
                        <Typography variant="h6">
                          <Currency>
                            {expenseAggregationResult?.total ?? 0}
                          </Currency>
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={0}></Grid>
                      <Grid
                        item
                        md={3}
                        xs={6}
                        container
                        justifyContent="flex-end"
                      >
                        <Typography variant="h6" fontWeight="bold">
                          Saldo
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={3}
                        xs={6}
                        container
                        justifyContent="flex-end"
                      >
                        <Typography variant="h6" fontWeight="bold">
                          <Currency>
                            {(incomeAggregationResult?.total ?? 0) -
                              (expenseAggregationResult?.total ?? 0)}
                          </Currency>
                        </Typography>
                      </Grid>
                      {selectedDateField === 'dateOfPayment' && (
                        <ExpectedSaldoRow
                          incomeAggregationResult={incomeAggregationResult}
                          expenseAggregationResult={expenseAggregationResult}
                          outstandingAggregationResult={
                            outstandingAggregationResult
                          }
                        />
                      )}
                    </Grid>
                  </Stack>
                </Stack>
              );
            })
            .reverse()}
        </Stack>
      </Stack>
    </Page>
  );
};

type ExpectedSaldoRowProps = {
  incomeAggregationResult: AggregationResult | undefined;
  expenseAggregationResult: AggregationResult | undefined;
  outstandingAggregationResult: AggregationResult | undefined;
};

const ExpectedSaldoRow = ({
  incomeAggregationResult,
  expenseAggregationResult,
  outstandingAggregationResult,
}: ExpectedSaldoRowProps): ReactElement | null => {
  const outstandingTotal = outstandingAggregationResult?.total ?? 0;
  const expectedSaldo =
    (incomeAggregationResult?.total ?? 0) +
    outstandingTotal -
    (expenseAggregationResult?.total ?? 0);
  if (outstandingTotal === 0) {
    return null;
  }
  return (
    <>
      <Grid item md={6} xs={0}></Grid>
      <Grid item md={3} xs={6} container justifyContent="flex-end">
        <Typography variant="body1">erwartet</Typography>
      </Grid>
      <Grid item md={3} xs={6} container justifyContent="flex-end">
        <Typography variant="body1">
          <Currency>{expectedSaldo}</Currency>
        </Typography>
      </Grid>
    </>
  );
};
