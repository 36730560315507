import React, { ReactElement, useState } from 'react';
import { Backdrop, CircularProgress, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import { TextInput } from '../inputs/TextInput';

const GITLAB_DATA_REPOSITORY_ID_KEY = 'gitlab-data-repository-id';
const GITLAB_DATA_FILE_PATH_KEY = 'gitlab-data-file-path';

type GitlabDataUrlType = {
  repositoryId: string;
  filePath: string;
  reference?: string;
};

export type GitlabFormData = Partial<GitlabDataUrlType> & {
  privateAccessToken: string;
};

const formatDataUrl = (data: GitlabDataUrlType): string => {
  const encodedUrl = encodeURIComponent(data.filePath);
  const definedReference = (data.reference ?? 'main') || 'main';
  return `https://gitlab.com/api/v4/projects/${data.repositoryId}/repository/files/${encodedUrl}/raw?ref=${definedReference}`;
};

const createShareUrl = (formData: GitlabFormData): string => {
  const url = new URL(document.location.origin + document.location.pathname);
  if (formData.reference) {
    url.searchParams.append('ref', formData.reference);
  }
  if (formData.filePath) {
    url.searchParams.append('path', formData.filePath);
  }
  if (formData.repositoryId) {
    url.searchParams.append('repid', formData.repositoryId);
  }
  if (formData.privateAccessToken) {
    url.searchParams.append('pt', formData.privateAccessToken);
  }
  return url.toString();
};

type GitlabFileDownloadProps = {
  onComplete: (data: string) => void;
  prefilledFormValue?: GitlabFormData;
};

export const GitlabFileDownload = ({
  onComplete,
  prefilledFormValue,
}: GitlabFileDownloadProps): ReactElement => {
  const [privateAccessToken, setPrivateAccessToken] = useState(
    prefilledFormValue?.privateAccessToken ?? '',
  );
  const [repositoryId, setRepositoryId] = useState(
    prefilledFormValue?.repositoryId ??
      localStorage.getItem(GITLAB_DATA_REPOSITORY_ID_KEY) ??
      '',
  );
  const [filePath, setFilePath] = useState(
    prefilledFormValue?.filePath ??
      localStorage.getItem(GITLAB_DATA_FILE_PATH_KEY) ??
      '',
  );
  const [reference, setReference] = useState(
    prefilledFormValue?.reference ?? '',
  );
  const [isLoading, setIsLoading] = useState(false);
  const fetchFromUrl = (): void => {
    setIsLoading(true);
    const dataUrl = formatDataUrl({ repositoryId, filePath, reference });
    fetch(dataUrl, {
      headers: { 'PRIVATE-TOKEN': privateAccessToken },
    })
      .then(async (response) => {
        if (response.ok) {
          localStorage.setItem(GITLAB_DATA_REPOSITORY_ID_KEY, repositoryId);
          localStorage.setItem(GITLAB_DATA_FILE_PATH_KEY, filePath);
        }
        return await response.text();
      })
      .then(async (rawData) => {
        setIsLoading(false);
        onComplete(rawData);
      })
      .catch((e) => {
        setIsLoading(false);
        return e;
      });
  };

  return (
    <Stack gap={2} width={'100%'} style={{ position: 'relative' }} pt={1}>
      <Backdrop
        open={isLoading}
        style={{ position: 'absolute', zIndex: 1000, borderRadius: '4px' }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <TextInput
        label="Private Access Token"
        value={privateAccessToken}
        onChange={(e) => setPrivateAccessToken(e.target.value)}
        fullWidth
      />
      <TextInput
        label="Repository ID"
        value={repositoryId}
        onChange={(e) => setRepositoryId(e.target.value)}
        fullWidth
      />
      <TextInput
        label="Dateipfad"
        value={filePath}
        onChange={(e) => setFilePath(e.target.value)}
        fullWidth
      />
      <TextInput
        label="Referenz (Branch, Tag, Commit)"
        value={reference}
        onChange={(e) => setReference(e.target.value)}
        fullWidth
      />
      <Button
        variant="contained"
        disabled={!privateAccessToken || !repositoryId || !filePath}
        onClick={async () => fetchFromUrl()}
      >
        Importieren
      </Button>
      <Typography variant="body2" fontSize={'11px'} color="gray">
        URL:
        <br />
        {createShareUrl({
          filePath,
          repositoryId,
          reference,
          privateAccessToken,
        })}
      </Typography>
    </Stack>
  );
};
