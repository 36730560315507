import {
  ShareholderCostCenterKey,
  shareholderCostCenterKeys,
  TransactionClassification,
} from '../model/TransactionClassification';
import {
  AggregationResult,
  toTemporalAggregation,
  useTemporalAggregation,
} from './useTemporalAggregation';

export type PrivateWithdrawalsShareholderResult = {
  shareholderCostCenterKey: ShareholderCostCenterKey;
  withdrawalAggregations: AggregationResult[];
  companyExpensesPaidByPrivateAccountAggregations: AggregationResult[];
};

export const toPrivateWithdrawalsCalculations = (
  transactions: TransactionClassification[],
): PrivateWithdrawalsShareholderResult[] => {
  const expenseTransactions = transactions.filter(
    (tC) => tC.type === 'EXP' || tC.type === 'EXP_NE',
  );
  return shareholderCostCenterKeys.map((shareholderCostCenterKey) => {
    const expenseTransactionsByShareholder = expenseTransactions.filter(
      (tC) => tC.costCenter === shareholderCostCenterKey,
    );
    const expenseAggregationByShareholder = toTemporalAggregation(
      expenseTransactionsByShareholder,
      'dateOfPayment',
      'YEAR',
    );

    const companyExpensesByShareholderBankaccount = expenseTransactions
      .filter((tC) => {
        switch (shareholderCostCenterKey) {
          case 'PRIV_GH':
            return tC.bankingAccount === 'Privatkonto Gerald Hoheneder';
          case 'PRIV_HW':
            return tC.bankingAccount === 'Privatkonto Herbert Weissmann';
          case 'PRIV_JS':
            return tC.bankingAccount === 'Privatkonto Jimi Steidl';
          default:
            return false;
        }
      })
      .filter((tC) => tC.costType !== 'PK_SOZ');
    const companyExpensesByShareholderBankaccountAggregation =
      useTemporalAggregation(
        companyExpensesByShareholderBankaccount,
        'dateOfPayment',
        'YEAR',
      );

    const years = Array.from(
      new Set(
        companyExpensesByShareholderBankaccountAggregation
          .map((s) => s.aggregator)
          .concat(expenseAggregationByShareholder.map((s) => s.aggregator)),
      ),
    ).sort((a, b) => a - b);
    years.map((year) => {
      const withdrawalAggregation = expenseAggregationByShareholder.find(
        (s) => s.aggregator === year,
      );
      const companyExpensesPaidByPrivateAccountAggregation =
        companyExpensesByShareholderBankaccountAggregation.find(
          (s) => s.aggregator === year,
        );
      const netWithdrawals =
        (withdrawalAggregation?.total ?? 0) -
        (companyExpensesPaidByPrivateAccountAggregation?.total ?? 0);
      return {
        shareholderCostCenterKey,
        year,
        withdrawalAggregation,
        companyExpensesPaidByPrivateAccountAggregation,
        netWithdrawals,
      };
    });

    return {
      shareholderCostCenterKey,
      withdrawalAggregations: expenseAggregationByShareholder,
      companyExpensesPaidByPrivateAccountAggregations:
        companyExpensesByShareholderBankaccountAggregation,
    };
  });
};
