import { compact } from 'lodash';

import {
  isParsable,
  PaymentType,
  TransactionClassification,
  TransactionClassificationType,
} from '../model/TransactionClassification';

export const parseTransactionClassifications = (
  data: unknown[],
): TransactionClassification[] => {
  const parsed = compact(data.map(parseTransactionClassification));
  const successful = parsed.length;
  const errors = data.length - parsed.length;
  // eslint-disable-next-line no-console
  const logText = `Parsed ${data?.length} entries. Successful=${successful}, Errors=${errors}`;

  if (errors > 0) {
    console.error(logText);
  } else {
    console.log(logText);
  }
  return parsed;
};

const parseType = (
  type?: string,
  data?: unknown,
): TransactionClassificationType => {
  if (
    type === 'EXP' ||
    type === 'INC' ||
    type === 'INC_NE' ||
    type === 'EXP_NE'
  ) {
    return type;
  }
  throw Error(
    `Unknown value='${
      type == null ? '' : type
    }' for field 'type'. Entry=${JSON.stringify(data)}`,
  );
};

const parsePaymentType = (
  paymentType?: string,
  data?: unknown,
): PaymentType => {
  if (paymentType === 'BANK_TRANSFER' || paymentType === 'CASH') {
    return paymentType;
  }
  throw Error(
    `Unknown value='${
      paymentType == null ? '' : paymentType
    }' for field 'paymentType'. Entry=${JSON.stringify(data)}`,
  );
};

const parseReverseCharge = (data?: unknown): boolean => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const value = data?.reverseCharge;
  const bool =
    value === true ||
    value === 'ja' ||
    value === 'TRUE' ||
    value === 1 ||
    value === 'true' ||
    value === 'JA';
  return bool;
};

const parseTransactionClassification = (
  data: unknown,
): TransactionClassification | undefined => {
  if (!isParsable(data)) {
    return undefined;
  }
  try {
    return {
      type: parseType(data.type, data),
      dateOfInvoice: parseNullableDate(data.dateOfInvoice),
      grossAmount: data.grossAmount,
      vatPercent: data.vatPercent,
      vatAmount: data.vatAmount,
      netAmount: data.netAmount,
      costType: data.costType,
      costCenter: data.costCenter,
      businessPartner: data.businessPartner,
      paymentType: parsePaymentType(data.paymentType, data),
      dateOfPayment: parseNullableDate(data.dateOfPayment),
      receiptId: data.receiptId,
      urlToReceipt: data.urlToReceipt,
      nameOfDocument: data.nameOfDocument,
      description: data.description,
      referenceReceiptId: data.referenceReceiptId,
      accountingNumber: data.accountingNumber,
      usefulLifeInMonths: data.usefulLifeInMonths,
      receiptError: data.receiptError,
      vatId: data.vatId,
      reverseCharge: parseReverseCharge(data),
      receiptCurrencyCode: data.receiptCurrencyCode,
      receiptCurrencyAmount: data.receiptCurrencyAmount,
      exchangeRate: data.exchangeRate,
      thirdPartyCountryCode: data.thirdPartyCountryCode,
      workingHours: data.workingHours,
      netHourlyRate: data.netHourlyRate,
      bankingAccount: data.bankingAccount,
    };
  } catch (e: unknown) {
    console.warn('Error parsing TransactionClassification.', e, data);
    return undefined;
  }
};

const parseNullableDate = (date?: Date): Date | undefined => {
  if (date == null) {
    return undefined;
  }
  return new Date(Date.parse(date.toString()));
};
