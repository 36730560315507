import React, { ReactElement, ReactNode } from 'react';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

export type Option<T = string> = {
  value: T & string;
  label: string;
};

const getLabelByValue = <T,>(
  options: Array<Option<T>>,
  value: T,
): string | undefined => {
  return options.find((o) => o.value === value)?.label;
};

type BaseSelectProps<OptionType = string> = {
  name?: string;
  label?: string;
  tooltip?: string;
  options: Array<Option<OptionType>>;
  multiple?: boolean;
  value: OptionType | OptionType[] | undefined;
  onChange: (
    event: SelectChangeEvent<OptionType | OptionType[]>,
    child: ReactNode,
  ) => void;
  onClose?: (value: OptionType | OptionType[] | undefined) => void;
  formHelperText?: ReactElement;
  error?: boolean;
  disabled?: boolean;
};

export const SelectInput = <OptionType,>({
  name,
  label,
  tooltip,
  options,
  multiple,
  value,
  onChange,
  onClose,
  formHelperText,
  error,
  ...props
}: BaseSelectProps<OptionType>): ReactElement => {
  return (
    <FormControl variant="outlined" fullWidth error={error}>
      <InputLabel>{label}</InputLabel>
      <Select<OptionType | OptionType[]>
        {...props}
        name={name}
        label={label}
        multiple={multiple}
        value={value ?? ''}
        onChange={onChange}
        onClose={() => onClose?.(value)}
        renderValue={
          multiple
            ? (selected) => {
                if (Array.isArray(selected)) {
                  return selected
                    .map((value) => getLabelByValue(options, value) ?? value)
                    .join(', ');
                }
              }
            : undefined
        }
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value} dense>
              {multiple ? (
                <>
                  <Checkbox
                    checked={
                      !!value &&
                      Array.isArray(value) &&
                      value?.includes(option.value)
                    }
                  />
                  <ListItemText primary={option.label} />
                </>
              ) : (
                option.label
              )}
            </MenuItem>
          );
        })}
      </Select>
      <>{formHelperText && formHelperText}</>
    </FormControl>
  );
};
