import React, { PropsWithChildren, ReactElement } from 'react';

type CurrencyProps = {
  currency?: string;
};

export const Currency = ({
  children,
  currency,
}: PropsWithChildren<CurrencyProps>): ReactElement => {
  const currencyFormat = new Intl.NumberFormat('de-AT', {
    style: 'currency',
    currency: currency != null ? currency : 'EUR',
  });
  if (children == null || isNaN(children as number)) {
    return <>{children}</>;
  }
  return <>{currencyFormat.format(children as number)}</>;
};
