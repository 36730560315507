import React, { ReactElement } from 'react';

import { TransactionClassification } from '../../model/TransactionClassification';
import { parseTransactionClassifications } from '../../parser/TransactionClassificationParser';
import { FileUploadInput } from '../inputs/FileUploadInput';
import { parseCsv } from './csvUtil';

type TransactionClassificationFileUploadProps = {
  onChange: (transactionClassifications: TransactionClassification[]) => void;
};
export const TransactionClassificationFileUpload = ({
  onChange,
}: TransactionClassificationFileUploadProps): ReactElement => {
  return (
    <FileUploadInput
      accept={'.csv'}
      label={'CSV Datei auswählen'}
      onComplete={async (file) => {
        if (!file) {
          return;
        }
        const csvData = await parseCsv(file);
        const results = parseTransactionClassifications(csvData);
        onChange(results);
      }}
    />
  );
};
